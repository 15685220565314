import { GatsbyBrowser } from 'gatsby'
import 'prism-themes/themes/prism-vsc-dark-plus.css'

import './src/styles/global.css'

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const currentPosition = getSavedScrollPosition(location)

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]))
  }, 0)

  return false
}
